import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { CurrentUserProvider } from './contexts/CurrentUserContext';
import reportWebVitals from './reportWebVitals';

import GlobalStyle from 'style/GlobalStyle';
import 'style/font.css';

ReactDOM.render(
	<React.StrictMode>
		<GlobalStyle />
		<CurrentUserProvider>
			<App />
		</CurrentUserProvider>
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
