const routerUrl: { [Key: string]: string } = {
	mainPage: '/',
	termPage: '/term',
	introPage : '/intro',
	productPage : '/product',
	privacyPage: '/privacy',
	processPage : '/process',
	helpPage : '/help',
	signUpPage : '/signup',
	loginPage : '/login',
};

export default routerUrl;
