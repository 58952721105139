import axios from 'axios';
import { useCookies } from 'react-cookie';
import { getSession, signOut, signIn, signUp, signUpConfirm } from 'api/cognito';

function useAPI(): any {
	const [cookies, setCookie, removeCookie] = useCookies();
	const idToken = cookies.id_token;

	const API: any = axios.create({
		baseURL: process.env.REACT_APP_API_URL || '',
		headers: {
			Authorization: idToken,
		},
	});

	const APIs: any = {
		getAll: () => API.get('/get'),
	};

	const user = {
		create: (params: {
			user_phone: string;
			nickname: string;
			password: string;
		}) => API.post('/users/insert', params),
		forgotPassword: (params: { new_password: string }) =>
			API.post('/users/change-pwd', params),
		signUpCheck: async({cognitoUser, confirmCode} : {cognitoUser: any, confirmCode: string | number}) =>
			{
				await signUpConfirm({cognitoUser, confirmCode})
			},
		signUp: async(userInfo: { userId: string;
			password: string;
			name?: string;
			phoneNumber : string;
		}) => {
			const result = await signUp(userInfo)
			return result
		}
	};

	const session = {
		insert: (params: { userId: string; password: string }) =>
			signIn(params),
		get: () => getSession(),
		delete: () => signOut(),
	};


	const cookie = {
		get: () => idToken,
		create: (value: string) => setCookie('id_token', value, { path: '/' }),
		delete: () => removeCookie('id_token', { path: '/' }),
	};


	return [
		{
			API,
			APIs,
			user,
			cookie,
			session
		},
	];
}

export default useAPI;
