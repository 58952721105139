const theme: {[key : string] : any} = {
	white: '#ffffff',
	indigo : '#00334F',

	red: '#EB4141',

	ivory: '#F9F9F9',

	black: '#333333',
	lightBlack: '#1A1A1A',

	sky: '#0098FF',
	darkSky: '#0B82DC',

	blue: '#0676ED',

	shadow: 'rgba(0, 0, 0, 0.18)',

	lightGray: '#808080',
	darkGray: '#575757',
	whiteGray : '#F1F1F1',
	gray: '#979797',

	levelBlue: {
		1: '#E5F2FB',
		2: '#B0D7F4',
		3: '#78BAEC',
		4: '#419EE3',
		5: '#0B82DC',
	},
};

export default theme;
