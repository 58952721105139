import styled from 'styled-components';

import GlobalStyled from '../../style/GlobalStyled';

const Styled = {
	Body: styled(GlobalStyled.Body)`
		padding: 0;
		background-color: ${props => props.theme.lightBlack};
	`,
	Container: styled(GlobalStyled.Container)`
		padding: 2rem 0;
		background-color: ${props => props.theme.lightBlack};
		color: ${props => props.theme.lightGray};
	`,
	LeftCol: styled(GlobalStyled.Col)`
		align-items: flex-start;
	`,
};

const GlobalFooter = () => (
	<Styled.Body>
		<Styled.Container>
			<GlobalStyled.Row>
				<Styled.LeftCol width={30}>
					<img
						alt="logo-footer-company"
						src={require('images/logo-footer-company.png').default}
					/>
				</Styled.LeftCol>
				<GlobalStyled.Col width={70}>
					<GlobalStyled.HeightRow>
						<GlobalStyled.Row bottom={0.5}>
							<GlobalStyled.Col width={20}>
								<b>회사명</b> : (주)조은몰드
							</GlobalStyled.Col>

							<GlobalStyled.Col width={15}>
								<b>대표이사</b> : 하진호
							</GlobalStyled.Col>
							<GlobalStyled.Col width={65}>
								<b>사업자번호</b> : 780-86-00094
							</GlobalStyled.Col>
						</GlobalStyled.Row>
						<GlobalStyled.Row bottom={0.5}>
							<b>주소</b> : 경기도 광주시 초월읍 동막골길
							232(학동리 120) (우편번호 :12731)
						</GlobalStyled.Row>
						<GlobalStyled.Row bottom={0.5}>
							<GlobalStyled.Col width={30}>
								<b>Tel</b> : 031-767-5533 / 031-767-5523
							</GlobalStyled.Col>

							<GlobalStyled.Col width={70}>
								<b>Fax</b> : 031-768-4455
							</GlobalStyled.Col>
						</GlobalStyled.Row>
						<GlobalStyled.Row bottom={3}>
							<b>E-Mail</b> : xotndnr7@naver.com
						</GlobalStyled.Row>
						<GlobalStyled.Row>
							COPYRIGHTⓒ (주)조은몰드., LTD. All rights reserved.
						</GlobalStyled.Row>
					</GlobalStyled.HeightRow>
				</GlobalStyled.Col>
			</GlobalStyled.Row>
		</Styled.Container>
	</Styled.Body>
);

export default GlobalFooter;
