import styled from 'styled-components';
import GlobalStyled from 'style/GlobalStyled';

import theme from 'style/theme';

import routerUrl from 'config/routerUrl';

import useCurrentUser from 'hooks/useCurrentUser';

const Styled = {
	Body: styled(GlobalStyled.Body)`
		border-bottom: 1px solid ${props => props.theme.gray};
		height: auto;
		padding: 0;
	`,
	Wrapper: styled(GlobalStyled.CenterRow)`
		font-size: 1.15rem;
		cursor: default;
		text-align: center;
		margin-top: -1px;
	`,
	LogoImg: styled.img`
		padding: 0.5rem 0;
	`,
	RightWrapper: styled(GlobalStyled.Col)`
		height: 100%;
	`,
	NavLinkCol: styled(GlobalStyled.CenterCol)`
		color: #666666;
		height: 100%;
		margin-top: 2px;
	`,
	NavLink: styled(GlobalStyled.NavLink)`
		display: flex;
		height: 100%;
		align-items: center;
		justify-content: center;
	`,
	Container: styled(GlobalStyled.Container)`
		width: 1110px;
	`,
};

const GlobalHeader = () => {
	const { currentUser, deleteSession } = useCurrentUser();

	let linkArray = [
		{
			value: '회사소개',
			to: routerUrl.introPage,
		},
		{
			value: '제품소개',
			to: routerUrl.productPage,
		},
		{
			value: '공정',
			to: routerUrl.processPage,
		},
		{
			value: '견적문의',
			to: routerUrl.helpPage,
		},
		{
			value: '로그인',
			to: routerUrl.loginPage,
		},
	];

	if (currentUser) {
		linkArray = [
			{
				value: '회사소개',
				to: routerUrl.introPage,
			},
			{
				value: '제품소개',
				to: routerUrl.productPage,
			},
			{
				value: '공정',
				to: routerUrl.processPage,
			},
			{
				value: '견적문의',
				to: routerUrl.helpPage,
			},
		];
	}

	const activeStyle = {
		borderBottom: `5px solid ${theme.indigo}`,
		paddingTop: '5px',
		fontWeight: 500,
		color: '#333333',
	};

	const headerLinkList = linkArray.map(
		(res: { to: string; value: string }, i: number) => {
			const { to, value } = res;
			return (
				<Styled.NavLinkCol width={100 / 5} key={i}>
					<Styled.NavLink
						to={to}
						activeStyle={activeStyle}
						onClick={(e: any) => {
							e.preventDefault();
							window.location.href = to;
						}}
					>
						{value}
					</Styled.NavLink>
				</Styled.NavLinkCol>
			);
		},
	);

	const handleClickSignOut = async () => {
		if (currentUser) {
			await deleteSession();
		} else {
			window.location.href = '/login';
		}
	};

	return (
		<Styled.Body>
			<Styled.Container>
				<GlobalStyled.Row>
					<Styled.Wrapper>
						<GlobalStyled.Col width={40}>
							<GlobalStyled.Link to="/">
								<Styled.LogoImg
									alt="header-company"
									src={
										require('images/logo-header-company.png')
											.default
									}
								/>
							</GlobalStyled.Link>
						</GlobalStyled.Col>
						<Styled.RightWrapper width={60}>
							{headerLinkList}
							{currentUser ? (
								<Styled.NavLinkCol
									width={100 / 5}
									onClick={handleClickSignOut}
								>
									<GlobalStyled.TransparentButton>
										<GlobalStyled.CenterRow>
											로그아웃
										</GlobalStyled.CenterRow>
									</GlobalStyled.TransparentButton>
								</Styled.NavLinkCol>
							) : (
								''
							)}
						</Styled.RightWrapper>
					</Styled.Wrapper>
				</GlobalStyled.Row>
			</Styled.Container>
		</Styled.Body>
	);
};

export default GlobalHeader;
